import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Icon from '../../components/Icon'
import { P, H4, H2 } from '../../styleElements'
import mq from '../../utils/mediaQuery'

const ProductCard = ({
  banner,
  icon,
  title,
  description,
  to = [],
  linkLabel,
  linkImage,
  showBorder,
  ...rest
}) => {
  const getLinkContainerLayout = to => {
    const hasExactlyOneLabel = to.filter(item => item.linkLabel).length === 1
    const hasExactlyOneImage = to.filter(item => item.linkImage).length === 1
    return hasExactlyOneLabel && hasExactlyOneImage
      ? 'space-between'
      : 'flex-start'
  }
  const layout = getLinkContainerLayout(to)
  return (
    <Root {...rest}>
      <Img fluid={banner} objectFit="cover" objectPosition="50% 50%" />
      <Container>
        <Header>
          <IconImage
            showBorder={showBorder}
            fluid={icon}
            objectFit="cover"
            objectPosition="50% 50%"
          />
          <H2>{title}</H2>
        </Header>
        <Content>{description}</Content>
        <LinkContainer layout={layout}>
          {to.map(({ url, linkLabel, linkImage }) => (
            <LinkWrapper key={url} href={url} target="_blank">
              {linkLabel ? (
                <LinkLabelWrapper>
                  <LinkLabel>See website </LinkLabel>
                  <Icon name="next" size={24} />
                </LinkLabelWrapper>
              ) : (
                <LinkImage
                  fixed={linkImage}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              )}
            </LinkWrapper>
          ))}
        </LinkContainer>
      </Container>
    </Root>
  )
}

ProductCard.propTypes = {
  banner: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  to: PropTypes.arrayOf(PropTypes.object).isRequired,
  LinkLabel: PropTypes.string,
  linkImage: PropTypes.object,
  showBorder: PropTypes.bool
}

ProductCard.defaultProps = {
  LinkLabel: '',
  linkImage: null
}

const Root = styled.div`
  border-radius: 5px;
  background-color: ${props => props.theme.color.white};
  overflow: hidden;

  ${mq.md} {
    display: flex;
    flex-direction: column;
  }
`

const Container = styled.article`
  padding: 18px 13px 30px;
  display: flex;
  flex-direction: column;

  ${mq.md} {
    flex-grow: 1;
  }
`

const Header = styled.header`
  min-height: 42px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

const IconImage = styled(Img)`
  width: 40px;
  margin-right: 15px;

  border: ${props =>
    props.showBorder ? '1px solid rgba(0, 0, 0, 0.07)' : 'none'};
  border-radius: ${props => (props.showBorder ? '25%' : 'none')};
`

const Content = styled(P)`
  margin-bottom: 20px;

  ${mq.md} {
    flex-grow: 1;
    min-height: 150px;
  }
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: ${({ layout }) =>
    layout === 'space-between' ? 'space-between' : 'flex-start'};
  align-items: center;
  flex-wrap: wrap;
`

const LinkWrapper = styled.a`
  display: flex;
  align-items: center;
  margin: 0px 12px 8px 0px;
`

const LinkLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const LinkLabel = styled(H4)`
  color: ${props => props.theme.color.primary1};
`

const LinkImage = styled(Img)`
  min-width: 120px;
`

export default ProductCard
